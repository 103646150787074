/* Layout */

html {
	scroll-behavior: smooth;
}
body {
	overflow: inherit !important;
}
.mco-view-default {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.mco-view-root.no-animation {
	padding-top: 70px;
}
.mco-view-component-desktopmenu__item--selected {
	opacity: 0.8 !important;
}
.MuiTypography-colorSecondary {
	color: #3a3a3a !important;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
	padding: 0 !important;
}
header {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
header::-webkit-scrollbar {
	display: none;
}

/* Menü */

body.menu-open .mco-view-component-menu-header,
body.menu-open .mco-view-component-megamenu__container,
body.menu-open .mco-view-component-contact {
	right: 0 !important;
}

/* anchor scroll position fix (header height + 60px) */

:target:before {
	content: "";
	display: block;
	height: 120px;
	margin-top: -120px;
}

/* Elemente */

.mco-elements {
	gap: 0 !important;
}
.MuiContainer-root:last-child {
	margin-bottom: 50px !important;
}
.MuiContainer-root.mco-container-teasergroup2columns:last-child {
	margin-bottom: 0 !important;
}

/* Überschriften */

.mco-container-headline-1 {
	max-width: none !important;
}
.MuiTypography-h1,
.MuiTypography-h2 {
	font-size: 25px !important;
	font-weight: 400 !important;
	text-align: center;
}
.mco-container-headline + .mco-container-headline .MuiTypography-h2 {
	font-size: 20px !important;
	margin-top: -36px;
}
@media all and (min-width: 600px) {
	.mco-container-headline + .mco-container-headline .MuiTypography-h2 {
		margin-top: -45px;
	}
}

/* Aufzählung */

.mco-list-01__headline {
	margin-bottom: 24px !important;
}

/* Bild-Text Kombination */

.mco-container-imagetext > div {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.mco-imagetext-01__title {
	margin-top: 20px !important;
	text-align: left !important;
}

/* Einzelbild */

.mco-container-singleimage {
	max-width: none !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.mco-singleimage-01__image img {
	max-height: 700px;
}
.mco-singleimage-01__content {
	text-align: center !important;
}
.mco-singleimage-01__content-title {
	font-size: 2.25rem !important;
}
.mco-singleimage-01__content-title + .mco-singleimage-01__content-text {
	margin-top: -10px !important;
}

/* Galerie */

.mco-container-headline + .mco-container-gallery {
	margin-top: 32px !important;
}
.mco-container-gallery {
	max-width: none !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.mco-gallery-01__wrapper {
	grid-gap: 0 !important;
	grid-template-columns: repeat(2, 1fr) !important;
}
/*
.mco-gallery-01__list {
	margin: 0 !important;
	width: 100%;
}
.mco-gallery-01__list-item {
	padding: 0 !important;
	width: 50% !important;
}
.mco-container-gallery li:nth-child(2n-1) .mco-gallery-01__title {
	text-align: right;
}
.mco-gallery-01__image {
	display: block;
	max-width: 100%;
	left: 0 !important;
	transform: none !important;
	top: 0 !important;
	transition: transform 500ms ease;
}
*/
.mco-gallery-01__item {
	overflow: hidden;
}
.mco-gallery-01__item img {
	transition: transform 500ms ease;
}
.mco-gallery-01__item:hover img {
	transform: scale(1.05);
}
/*
.MuiImageListItemBar-title {
	color: #000000 !important;
	text-shadow: none !important;
}
*/
.mco-overlay-toolbar .mco-overlay-close svg path:first-child {
	fill: none !important;
}
.mco-overlay-arrow-prev svg path:first-child {
	fill: none !important;
}
.mco-overlay-arrow-next svg path:first-child {
	fill: none !important;
}

@media all and (min-width: 600px) {
	.mco-container-headline + .mco-container-gallery {
		margin-top: 40px !important;
	}
}
@media all and (min-width: 960px) {
	.mco-container-headline + .mco-container-gallery {
		margin-top: 56px !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-container-headline + .mco-container-gallery {
		margin-top: 64px !important;
	}
}

/* Galerie 3-spaltig */

.mco-container-gallery-three-columns {
	max-width: 1884px !important;
	padding: 0 !important;
}
.mco-container-gallery-three-columns .mco-gallery-01__wrapper {
	grid-template-columns: repeat(1, 1fr) !important;
}
/*
.mco-container-gallery-three-columns li:not(:hover) .mco-gallery-01__title {
	opacity: 0;
}
.mco-container-gallery-three-columns li .MuiImageListItemBar-titleWrap {
	margin: 0 !important;
}
.mco-container-gallery-three-columns li .mco-gallery-01__title {
	background: rgba(0, 0, 0, 0.5);
	align-items: flex-start !important;
	height: 100% !important;
	padding: 40px 32px 24px !important;
	transition: all 500ms ease;
	top: 0 !important;
}
.mco-container-gallery-three-columns li .MuiImageListItemBar-title {
	color: #FFFFFF !important;
	font-size: 3.0357142857142856rem !important;
	font-weight: 400 !important;
	line-height: 1.235 !important;
	transition: all 500ms ease;
}
.mco-container-gallery-three-columns li:not(:hover) .MuiImageListItemBar-title {
	transform: translate(0, 5px);
}
*/

/* Verweise */

.mco-references-01__headline {
	margin-bottom: 24px !important;
}
.mco-references-01__list {
	justify-content: center;
}
.mco-references-01__list-item {
	flex-basis: auto !important;
	padding: 8px !important;
}
.mco-references-01__list a {
	color: #ffffff !important;
	background: #3a3a3a !important;
	padding: 6px 16px !important;
}
.mco-references-01__list a:hover {
	opacity: 0.8;
}
.mco-references-01__list a > div {
	display: none !important;
}

/* Teaser */

.mco-container-teaser-grid {
	max-width: 1600px !important;
}
.mco-teaser-02 {
	overflow: hidden !important;
}
.mco-teaser-02__button {
	display: none !important;
}
.mco-teaser-02__text {
	display: none !important;
}
.mco-teaser-02 a .mco-teaser-02__title {
	color: #ffffff !important;
	opacity: 0;
	transform: translate(0, 5px);
	transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.mco-teaser-02 a .mco-teaser-02__image:after {
	background: rgba(0, 0, 0, 0.5);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	opacity: 0;
	top: 0;
	transition: opacity 300ms ease-in-out;
	width: 100%;
}
.mco-teaser-02 a:hover .mco-teaser-02__image:after {
	opacity: 1;
}
.mco-teaser-02 a:hover .mco-teaser-02__title {
	opacity: 1;
	transform: translate(0, 0);
}
.mco-teaser-02 a:hover .mco-teaser-02__content {
	transform: none !important;
	backdrop-filter: none !important;
}

/* Tabelle */

.MuiTableCell-head {
	background: #3a3a3a !important;
}

/* Download */

.mco-download-01__link:hover {
	color: var(--mco-palette-primary-main) !important;
	opacity: 0.5;
}
.mco-download-01__link:hover svg path:first-child {
	fill: var(--mco-palette-primary-main) !important;
}

/* Kontakt */

.mco-container-contact {
	background: #3a3a3a;
	max-width: none !important;
	padding: 0 !important;
}
.mco-extension-component-contact {
	max-width: 1920px;
	margin: 0 auto;
}

/* Gruppenteaser 2-spaltig */

.mco-container-teasergroup2columns {
	background: #3a3a3a;
	max-width: none !important;
	padding: 0 !important;
}

/* Gruppenteaser 3-spaltig */

.mco-container-teaser-grid {
	max-width: 1900px !important;
}

/* Leistungen */

.mco-container-services {
	max-width: 1920px !important;
}

/* Formular */

.MuiCheckbox-colorSecondary.Mui-checked {
	color: #000000 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked:hover,
.MuiIconButton-colorSecondary:hover {
	background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Video */

.mco-video-02__title {
	font-size: 3rem !important;
	line-height: 1.1 !important;
}

/* Elementabstände */

.mco-container-gallery + .mco-container-singleimage {
	margin-top: 0;
}
.mco-container-singleimage + .mco-container-gallery {
	margin-top: 0;
}

/* Cookie Meldung */

.mco-consent-slide-up__backdrop {
	z-index: 999;
}
.mco-consent-slide-up__text,
.mco-consent-slide-up__privacy {
	font-size: 16px !important;
	line-height: 1.4 !important;
}
.mco-consent-slide-up__button-config,
.mco-consent-slide-up__button-confirm {
	font-size: 1rem !important;
}

@media all and (min-width: 600px) {
	.mco-container-gallery-three-columns .mco-gallery-01__wrapper {
		grid-template-columns: repeat(2, 1fr) !important;
	}
}
@media all and (min-width: 960px) {
	.mco-view-root.no-animation {
		padding-top: 20px;
	}
	.MuiContainer-root:last-child {
		margin-bottom: 80px !important;
	}
	.MuiTypography-h1,
	.MuiTypography-h2 {
		font-size: 44px !important;
	}
	.mco-container-headline + .mco-container-headline .MuiTypography-h2 {
		font-size: 30px !important;
		margin-top: -65px;
	}
	.MuiImageListItemBar-title {
		font-size: 55px !important;
		font-weight: 400 !important;
		line-height: 55px !important;
	}
	.MuiImageListItemBar-titleWrap {
		margin-left: 32px !important;
		margin-right: 32px !important;
	}
	/*
	.mco-gallery-01__title {
		top: 35px !important;
	}
	*/
	.mco-container-singleimage + .mco-container-gallery {
		margin-top: 30px;
	}
	.mco-container-singleimage + .mco-container-gallery .mco-gallery-01__list {
		justify-content: space-between !important;
	}
	.mco-container-singleimage + .mco-container-gallery .mco-gallery-01__list-item {
		width: calc(50% - 15px) !important;
	}
	.mco-container-gallery + .mco-container-teasergroup2columns {
		margin-top: 30px;
	}
	.mco-gallery-01__list-item:hover .mco-gallery-01__image {
		transform: scale(1.05) !important;
	}
	.mco-container-gallery-three-columns .mco-gallery-01__wrapper {
		grid-template-columns: repeat(3, 1fr) !important;
	}
}
@media all and (min-width: 1500px) {
	.MuiTypography-h1,
	.MuiTypography-h2 {
		font-size: 55px !important;
	}
}
