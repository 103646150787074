/* Bank Gothic BT Light - latin */
@font-face {
	font-family: "Bank Gothic";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/bank-gothic-bt-light.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/bank-gothic-bt-light.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-300 - latin */
@font-face {
	font-family: "Exo 2";
	font-style: normal;
	font-weight: 300;
	src: local(""), url("../fonts/exo-2-v15-latin-300.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/exo-2-v15-latin-300.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-regular - latin */
@font-face {
	font-family: "Exo 2";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/exo-2-v15-latin-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/exo-2-v15-latin-regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-700 - latin */
@font-face {
	font-family: "Exo 2";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/exo-2-v15-latin-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/exo-2-v15-latin-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* alegreya-sans-sc-300 - latin */
@font-face {
	font-family: "Alegreya Sans SC";
	font-style: normal;
	font-weight: 300;
	src: local(""), url("../fonts/alegreya-sans-sc-300.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/alegreya-sans-sc-300.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-sans-sc-400 - latin */
@font-face {
	font-family: "Alegreya Sans SC";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/alegreya-sans-sc-400.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/alegreya-sans-sc-400.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-sans-sc-700 - latin */
@font-face {
	font-family: "Alegreya Sans SC";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/alegreya-sans-sc-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/alegreya-sans-sc-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}